
import { Swiper, Navigation } from "swiper";
// import pixstarData from "static/json/pixStarHundredData.json"
import pixstarData from "static/json/star_articles.json"
export default {
    name: 'pixstar-container',
    data() {
        return {
            member_data: [],
            data_index: 0,
            main_link: "https://pixstar.events.pixnet.net/2023/",
            model_id: "pixstar_2023",
        }
    },
    methods: {
        getRandomTenMember(item) {
            return item.sort(() => 0.5 - Math.random()).slice(0, 10);
        },
        spiltFirstString(str) {
            return str.split(", ")[0];
        },
        paFunction(index_number) {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::社群金點賞::blogger_card::'+ this.model_id, '社群金點賞::' + this.member_data[index_number].user_displayname + '/' + this.member_data[index_number].user_name, (index_number+1) + '.000' + (index_number+1)]);
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'imps::社群金點賞::article_card::'+ this.model_id, this.member_data[index_number].user_displayname + '/' + this.member_data[index_number].user_name + '::' + this.member_data[index_number].hot_article_link, (index_number+1) + '.000' + (index_number+1)]);
        },
        paClickFuntion() {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::社群金點賞::article_card::'+ this.model_id, this.member_data[this.data_index].user_displayname + '/' + this.member_data[this.data_index].user_name + '::' + this.member_data[this.data_index].hot_article_link, (this.data_index + 1) + '.000' + (this.data_index + 1)]);
        },
        paClickPicFuntion() {
            window._piq.push(['trackEvent', 'rs2022::十大頻道::首頁', 'click::社群金點賞::blogger_card::'+ this.model_id, '社群金點賞::' + this.member_data[this.data_index].user_displayname + '/' + this.member_data[this.data_index].user_name, (this.data_index + 1) + '.000' + (this.data_index + 1)]);
        },
    },
    async fetch() {
        this.member_data = this.getRandomTenMember(pixstarData);
    },
    mounted() {
        Swiper.use([Navigation]);
        this.swiper = new Swiper(".star-swiper", {
            direction: "horizontal",
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 12,
        });
        this.paFunction(0);
        this.swiper.on('slideChange', () => {
            this.data_index = this.swiper.realIndex;
            this.paFunction(this.swiper.realIndex)
        });

    }
}
